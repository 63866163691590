import './App.css';
import BodyComponent from './components/body/body_component';
import HeaderComponent from './components/header/headerComponent';
import FooterComponet from './components/footer/footerComponent';

function App() {
  return (<>
      
          <HeaderComponent></HeaderComponent>
          <BodyComponent></BodyComponent>
          <FooterComponet></FooterComponet>
      </>
      
  );
}

export default App;
