import banner from "../../images/banner.jpeg";
import './banner.css';
const BodyComponent=(props)=>{
    return <>
        <div className="container">
                <img className="banner" src={banner} alt="Mô tả cho ảnh của bạn" />
                <h1>Nội dung website</h1>
                <p>Môt vài lời nói đầu</p>
                <p>Chuẩn bị môi trường phát triển</p>
                <p>phát triển tính năng cốt lõi</p>
        </div>
    </>
}

export default BodyComponent;