const RegisterComponent = ()=>{
    return <>
     <div className="modal fade" id="registerModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
            <div className="modal-content">
            <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">Đăng ký</h1>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
                <div class="mb-3">
                    <label for="email" className="form-label">Email</label>
                    <input type="email" className="form-control" id="email" placeholder="name@example.com"/>
                </div>

                <div class="mb-3">
                    <label for="name" className="form-label">Tên</label>
                    <input type="text" className="form-control" id="name" placeholder="your name"/>
                </div>

                <div class="mb-3">
                    <label for="phone" className="form-label">Số điện thoại</label>
                    <input type="phone" className="form-control" id="phone" placeholder="phone number"/>
                </div>

                <div class="mb-3">
                <label for="inputPassword" className="form-label">Password</label>
                    <input type="password" id="inputPassword" className="form-control" aria-describedby="passwordHelpBlock"/>
                    <div id="passwordHelpBlock" className="form-text">
                        Mật khẩu của bạn phải dài từ 8-20 ký tự, chỉ chứa văn bản và số, không có khoảng cách, ký tự đặt biệt và emoji.
                    </div>
                </div>
                <div class="mb-3">
                        <label for="repassword" className="form-label">Nhập lại password</label>
                        <input type="password" id="repassword" className="form-control" aria-describedby="passwordHelpBlock"/>
                </div>
            </div>
            <div className="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Đóng</button>
                <button type="button" class="btn btn-primary">Đăng ký</button>
            </div>
            </div>
        </div>
    </div>
    </>

}

export default RegisterComponent;