import logo from "../../images/logo1.png";
import './header.css';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import RegisterComponent from "../register/registerComponent";
import LoginComponent from "../login/loginComponent";


function HeaderComponent(props){
  return (<>
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
            <div className="container-fluid">
                <span className="navbar-brand mb-0 h1">  <img className="logo" src={logo} alt="Mô tả cho ảnh của bạn" /></span>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <a className="nav-link active" aria-current="page" href="/">Trang Chủ</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="!#">Bài Học</a>
                        </li>
                        <li className="nav-item">
                        <   a className="nav-link" href="!#">Chạm  <FontAwesomeIcon icon={faHeart} color="red" /></a>
                        </li>

                        <li className="nav-item dropdown show_login_as_a_menu">
                            <a className="nav-link dropdown-toggle" href="!#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Tài Khoản
                            </a>
                            <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                <li><a className="dropdown-item" href="!#">Thông Tin</a></li>
                                <li><a className="dropdown-item" href="!#">Đổi Mật Khẩu</a></li>
                                <li><a className="dropdown-item" href="!#">Logout</a></li>
                            </ul>
                        </li>
                    </ul>
                
                    <div className="ms-auto">
                    <a href="!#" className="btn btn-primary me-2"  data-bs-toggle="modal" data-bs-target="#registerModal">Đăng ký</a>
                    <a href="!#" className="btn btn-outline-success" data-bs-toggle="modal" data-bs-target="#loginModal">Đăng Nhập</a>
                </div>
                </div>
            </div>
        </nav>
        <LoginComponent></LoginComponent>
        <RegisterComponent></RegisterComponent>
    </>
    );
}

export default HeaderComponent;